// eslint-disable-next-line
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import './theme.css' // default theme, used while loading the custom theme (voting-theme.css in the theme hook)
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import i18n from './i18n'
import { store } from './store'
import { useCustomColors } from './store/hooks'
import { usePageLoader } from './hooks/loaders'
import { useCustomTheme } from './hooks/theme'
import NotFoundPage from './pages/NotFoundPage'
import { AWSWAFCaptchaModal } from './AWSWAFCaptchaModal'
import React from 'react'
import PageLoader from './components/app/PageLoader'
import { GlobalStyles } from './GlobalStyles'

const DEFAULT_FAVICON = '/favicon.ico'
const JSAPI_URL = process.env.REACT_APP_JSAPI_URL as string

// dynamic pages
const PollingRouter = React.lazy(() => import('./pages/PollingPage'))
const SearchPollCodePage = React.lazy(() => import('./pages/SearchPollCodePage'))
const VerificationPage = React.lazy(() => import('./pages/VerificationPage'))
const PollResultsPage = React.lazy(() => import('./pages/PollResultsPage'))
const RegisterFormPage = React.lazy(() => import('./pages/RegisterFormPage'))
const BulletinBoardPage = React.lazy(() => import('./pages/BulletinBoardPage'))

const AppRouter = () => {
  const { loadDomainTheme, domainTheme } = useCustomColors()
  const { logoUrl, faviconUrl } = domainTheme ?? {}
  const { theme } = useCustomTheme()
  window.awsWafCookieDomainList = [window.location.hostname]

  usePageLoader(async () => {
    loadDomainTheme()
  }, [loadDomainTheme])

  return (
    <BrowserRouter>
      <AWSWAFCaptchaModal />
      <Helmet>
        <script type="text/javascript" src={JSAPI_URL} id="AwsWAFScript" defer />
        {theme.defaultThemeUrl && <link key={theme.defaultThemeUrl} rel="stylesheet" href={theme.defaultThemeUrl} />}
        {logoUrl && <link key={logoUrl} rel="prefetch" href={logoUrl} type="image/png" />}
        <link rel="icon" href={faviconUrl || DEFAULT_FAVICON} />
      </Helmet>
      <Routes>
        {/* TODO: add a home page (ask Sara) */}
        <Route path="/:censusId/form" element={<PageLoader page={RegisterFormPage} />} />
        <Route path="/:pollCode" element={<PageLoader page={PollingRouter} />} />
        <Route path="/:pollCode/admin-auth" element={<PageLoader page={PollingRouter} />} />
        <Route path="/:pollCode/token-auth" element={<PageLoader page={PollingRouter} />} />
        <Route path="/:pollCode/verification" element={<PageLoader page={VerificationPage} />} />
        <Route path="/:pollCode/results" element={<PageLoader page={PollResultsPage} />} />
        <Route path="/:pollCode/check-hash" element={<PageLoader page={BulletinBoardPage} />} />
        <Route path="/" element={<PageLoader page={SearchPollCodePage} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GlobalStyles theme={domainTheme} />
    </BrowserRouter>
  )
}

const AppProvider = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <I18nextProvider i18n={i18n}>
          <AppRouter />
        </I18nextProvider>
      </HelmetProvider>
    </Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<AppProvider />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
